@keyframes outgoing {
  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
  }

  25% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4),
      0 0 0 20px rgba(255, 255, 255, 0.2);
  }

  25% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4),
      0 0 0 30px rgba(255, 255, 255, 0.2);
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "jaldi";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* @media screen and (max-width: 995px) , screen and (max-height: 700px) {
  .back {
    background-color: aqua
  }  
} */
/* 
@media screen and (min-width: 1400px) and (min-height: 900px) {
  .back {
    background-color: aqua;
  }
} */

.css-94 {
  min-width: auto !important;
  width: 100%;
  align-items: center;
}

/* Please override css by another way - these class generated by random, it will affected to other elements  */
/* .css-146 {
  max-width: none;
}

.css-147 {
  max-width: none;
}

.css-178 {
  max-width: none;
}

.css-179 {
  max-width: none;
}

.css-180 {
  max-width: none;
}

.css-148 {
  max-width: none;
} */

.ui-chat li:nth-child(-n + 3) {
  display: none;
}

.fill-width {
  width: -webkit-fill-available;
  width: -moz-available;
}

.senderClass {
  background-color: rgba(3, 110, 245, 0.25);
  padding: 10px;
  border-radius: 4px;
}

.reciverClass {
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 4px;
}

.reciveName {
  margin-bottom: 5px;
  font-weight: 600;
}

.sendMediaFile {
  font-weight: 700;
  margin-left: 5px;
  display: inline-flex;
}

.sendMeassgesWithMedia {
  padding-top: 5px;
}

.sendviewMedia {
  padding-top: 13px;
  margin-right: 40px;
}

/* .react-select__control{
  background-color: transparent !important;
  border-radius: 1px !important;
} */

.react-select__indicator {
  padding: 7px !important;
}

.crop-container {
  position: relative;
  margin: 10px auto;
  width: 500px !important;
  height: 300px !important;
}

.word-break {
  word-break: break-word;
}

.lVApJ {
  width: 100% !important;
}

/* Please override css by another way - these class generated by random, it will affected to other elements  */
/* 
.root-205{
display: none !important
}

.root-213 {
  display: none !important;
}

.root-189 {
  display: none !important;
} */

/* massgess Hide class */
/* .root-span {
  color: white !important;
} */

.chat-composite > div > div > div > div > div > div > div > div:last-child {
  display: none !important;
}

/* send-box-css */
.send-box-wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  flex-shrink: 1;
}

.send-box-wrapper > .ms-Stack {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  flex-grow: 1;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.send-box-wrapper > .ms-Stack > div {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  margin: 0.25rem;
  overflow: hidden;
}

.react-switch-handle {
  height: 20px !important;
  width: 21px !important;
  top:2px !important
}

.react-switch-bg{
  height: 25px !important;
    width: 54px !important;
}

.send-box-wrapper > .ms-Stack > div > div {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.0625rem solid rgb(96, 94, 92);
  margin: 0.0625rem;
  max-width: 41.25rem;
  align-self: center;
  width: 100%;
}

.send-box-wrapper > .ms-Stack {
  text-overflow: ellipsis;
}

.send-box-wrapper > .ms-Stack > div > div > div {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  padding: 0px;

  background: blue;
  align-self: center;
  position: relative;
  width: 100%;
}

.send-box-wrapper > .ms-Stack > div > div > div > div {
  flex-shrink: 1;
  align-self: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.send-box-wrapper > .ms-Stack > div > div > div > div:first-child {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 8.25rem;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: 0px;
}

.send-box-wrapper > .ms-Stack > div > div > div > div:first-child > div > div {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background: rgb(255, 255, 255);
  cursor: text;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  min-height: 0px;
  outline: none;
}

.send-box-wrapper textarea {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 6px 4rem 6px 8px;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: none;
  background: none transparent;
  color: rgb(50, 49, 48);
  width: 100%;
  min-width: 0px;
  text-overflow: ellipsis;
  outline: red 5px;
  resize: none;
  min-height: 2.25rem;
  line-height: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  max-height: 108px;
  height: 36px;
}

.send-box-wrapper > .ms-Stack > div > div > div > div:last-child {
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  position: absolute;
  right: 0.3rem;
  top: 0px;
  bottom: 0px;
  gap: 0.25rem;
  align-items: center;
}

.send-box-wrapper > .ms-Stack > div > div > div > div:last-child {
  display: flex;
}

.send-box-wrapper .icon-wrapper .ms-Button {
  outline: transparent;
  position: relative;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 4px;
  border-radius: 2px;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  color: grey;
  margin: auto 0.313rem auto auto;
  user-select: none;
}

.send-box-wrapper .icon-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  position: absolute;
  right: 0.3rem;
  top: 0px;
  bottom: 0px;
  gap: 0.25rem;
  align-items: center;
}

.send-box-wrapper .icon-wrapper > div {
  display: flex;
}

.send-box-wrapper > .ms-Stack,
.send-box-wrapper > .ms-Stack > div {
  /* width: 100%; */
  width: auto;
}

/* end send-box-css */

.call-animation {
  background: transparent;
  width: 135px;
  height: 135px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  border: solid 5px transparent;
  animation: outgoing 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.callee-tile > div {
  display: flex;
  flex-direction: column;
}

.callee-tile > div .ms-Persona-details {
  align-items: center;
  position: absolute;
  bottom: -50px;
}
.callee-tile .ms-Persona-primaryText {
  display: none;
}

.video-gallery.incoming .ms-Stack > i {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0ic3ZnIiBmaWxsPSJjdXJyZW50Q29sb3IiPjxwYXRoIGQ9Ik0xMC44IDExLjUybDMuMzUgMy4zM2EuNS41IDAgMDAuNy0uN2wtMTMtMTNhLjUuNSAwIDEwLS43LjdMNS41IDYuMjFWOGEyLjUgMi41IDAgMDAzLjg4IDIuMDlsLjcyLjcxQTMuNSAzLjUgMCAwMTQuNSA4YS41LjUgMCAxMC0xIDAgNC41IDQuNSAwIDAwNCA0LjQ3djEuMDNhLjUuNSAwIDEwMSAwdi0xLjAzYy44Ny0uMSAxLjY2LS40NCAyLjMtLjk1eiI+PC9wYXRoPjxwYXRoIGQ9Ik0xMi4wNiA5Ljk0bC0uNzYtLjc2Yy4xMy0uMzcuMi0uNzcuMi0xLjE4YS41LjUgMCAwMTEgMGMwIC43LS4xNiAxLjM1LS40NCAxLjk0eiI+PC9wYXRoPjxwYXRoIGQ9Ik0xMC40OCA4LjM1bC00LjgtNC43OWEyLjUgMi41IDAgMDE0LjgyLjk0VjhjMCAuMTIgMCAuMjQtLjAyLjM1eiI+PC9wYXRoPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}

.microphone-button {
  margin: -1px;
}

.typing-indicator {
  padding-left: 14px;
}

.ms-Dialog-main {
  width: 200px;
  height: 250px;
}

/* .typing-indicator { 
  display: none !important; 
  margin: 0 auto; 
  position: relative; 
  } */

/* img, svg, video, canvas, audio, iframe, embed, object {
  display: none !important;
  vertical-align: middle;
} */

.chat-composite > div > div > div > div > div > div > .css-148 {
  max-width: 100%;
}

.list-notes {
  width: 300px;
  height: 500px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  left: 20px;
  bottom: 140px;
  z-index: 1010000;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  resize: vertical;
  overflow: hidden;
  min-height: 300px;
}

.active-note .note-header,
.list-notes .note-header {
  cursor: move;
  user-select: none;
  padding: 10px;
  -webkit-user-select: none;
}

.active-note,
.list-notes {
  --scrollbarBG: transparent;
  --thumbBG: lightgray;
}

.active-note .note-content::-webkit-scrollbar,
.list-note-content::-webkit-scrollbar {
  width: 10px;
}
.active-note .note-content,
.list-note-content {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.active-note .note-content::-webkit-scrollbar-track,
.list-note-content::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.active-note .note-content::-webkit-scrollbar-thumb,
.list-note-content::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.list-note-content {
  overflow: auto;
  min-height: 300px;
  height: calc(100% - 76px);
}

.list-note-content > ul > li {
  margin: 10px 10px 10px 0;
  padding: 10px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
}

.list-note-content > ul > li .note-item-content {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.list-note-content > ul > li:hover {
  opacity: 0.8;
}

.list-note-content > ul > li .note-created-on {
  position: relative;
  height: 24px;
  overflow: hidden;
}

.list-note-content > ul > li .note-created-date-time {
  position: absolute;
  right: 0;
}

.list-note-content > ul > li .remove-note-item {
  position: absolute;
  right: -50px;
}

.list-note-content > ul > li:hover .note-created-date-time {
  right: -300px;
}

.list-note-content > ul > li:hover .remove-note-item {
  right: 0;
}

.list-note-content > ul > li:first-child {
  margin-top: 0;
}

.note-created-on {
  display: flex;
  justify-content: right;
  font-size: 12px;
  color: #323232;
}

.active-note {
  position: absolute;
  left: 100px;
  bottom: 150px;
  z-index: 1010001;
  border: 1px solid #757575;
  border-radius: 10px;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  overflow: hidden;
  resize: both;
  min-height: 200px;
  min-width: 300px;
  max-height: 700px;
  max-width: 500px;
}

.active-note .note-content {
  margin-top: 45px;
  overflow: auto;
  padding: 10px;
}
.active-note .note-content > div {
  border: unset;
}

.active-note .note-content > div > div iframe,
.active-note .note-content > div > div {
  background-color: transparent;
}

.active-note .note-content .cke {
  height: calc(100% - 2px);
}
.active-note .note-content .cke_inner {
  height: 100%;
}

.list-note-content .note-item-content ol,
.list-note-content .note-item-content ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.list-note-content .note-item-content ul {
  list-style-type: disc;
}

.list-note-content .note-item-content ol {
  list-style-type: decimal;
}

.active-note .note-content:focus {
  outline: none;
}

.active-note .setting-header,
.active-note .note-header {
  position: absolute;
}

.active-note .note-header {
  background-color: white;
  top: -45px;
}

.active-note .setting-header {
  background-color: white;
  top: -150px;
}

.active-note .note-footer {
  bottom: -45px;
}

.active-note .setting-header.active,
.active-note:hover .note-header,
.active-note:focus .note-header {
  top: 0;
}

.active-note:hover .note-footer,
.active-note:focus .note-footer {
  bottom: 0;
}

.list-note-content > ul > li:hover .note-created-date-time,
.list-note-content > ul > li:hover .remove-note-item,
.list-note-content > ul > li .note-created-date-time,
.list-note-content > ul > li .remove-note-item,
.active-note .setting-header,
.active-note .setting-header.active,
.active-note .note-header,
.active-note:hover .note-header,
.active-note:focus .note-header,
.active-note .note-footer,
.active-note:hover .note-footer,
.active-note:focus .note-footer {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.active-note .note-header .saving {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.active-note .setting-header {
  border-bottom: 1px solid #757575;
  box-shadow: 2px 2px 9px rgba(33, 33, 33, 0.4);
}

.active-note .note-footer .cke_bottom {
  background: transparent;
  padding: 6px 8px 5px;
}

.active-note .background {
  width: 100%;
  height: 45px;
  margin: -10px;
  position: absolute;
  opacity: 0.9;
}

/* width */
.smallScroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.smallScroll::-webkit-scrollbar-track {
  background: #eef8f7;
  border-radius: 5px;
}

/* Handle */
.smallScroll::-webkit-scrollbar-thumb {
  background: #6bc5d2;
  border-radius: 5px;
}

/* Handle on hover */
.smallScroll::-webkit-scrollbar-thumb:hover {
  background: #84b6ba;
}

.ck {
  height: 250px;
}

.ckPreview {
  height: auto !important;
}

.ck ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ck ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ck-editor__editable_inline {
  border: 1px solid #6BC5D2 !important;
  border-top: 0 !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.ck.ck-content {
  background-color: white;
  max-width: 100% !important;
}

.ck.ck-toolbar {
  border: 1px solid #6BC5D2  !important;
  border-bottom: 0 !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  max-width: 100% !important;
}

.ck-rounded-corners {
  border-radius: 0 !important;
}

.react-datepicker__input-container {
  display: flex !important;
  align-items: center !important;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 31px !important;
  height: 35px !important;
  /* border-top-left-radius: 8px !important ;
  border-bottom-left-radius: 8px !important ; */
  width: 100% !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css2?family=Jaldi:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;600;700;800&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
